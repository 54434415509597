/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 299px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 56.024096385542165%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAAB5FcLKlRpQ//EABkQAQACAwAAAAAAAAAAAAAAAAIBEAMSIf/aAAgBAQABBQI9nKTrTmv/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEn/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAC/9oACAECAQE/AdLF/8QAFhAAAwAAAAAAAAAAAAAAAAAAACAi/9oACAEBAAY/AiV//8QAGhABAAMAAwAAAAAAAAAAAAAAAQARIRBRcf/aAAgBAQABPyEms0resru5dIkQDj//2gAMAwEAAgADAAAAEMv/AP/EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxBlCn//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QCLti/8QAGhABAQEAAwEAAAAAAAAAAAAAAREAIUFxUf/aAAgBAQABPxAlQhz7jhWvKATR2rzU8j9NAGXhneFm/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"New Hampshire origin\"\n        title=\"New Hampshire origin\"\n        src=\"/static/e40c04f57bec3c86bf9dc82c02f87811/4bf96/new-hampshire.jpg\"\n        srcset=\"/static/e40c04f57bec3c86bf9dc82c02f87811/4d5fb/new-hampshire.jpg 166w,\n/static/e40c04f57bec3c86bf9dc82c02f87811/4bf96/new-hampshire.jpg 299w\"\n        sizes=\"(max-width: 299px) 100vw, 299px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Even though New Hampshire was named after a county in England called Hampshire it was still the first colony to break away from British control in the late 1700’s."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
